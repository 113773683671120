.App {
  text-align: center;
}

/* responsive */
@media only screen and (max-width: 450px) {
  form {
    width: 70%;
  }

  .header {
    display: none !important;
  }

  .title-div {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }

  .logo {
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.5rem;
  }

  .form-div {
    width: 80%;
    display: block;
    margin: auto;
  }
}

@media only screen and (min-width: 451px) {
  .form-div {
    /* border: 3px solid rgb(221, 219, 0); */
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-div {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }

  .logo {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
  }
}

body {
  background-color: rgb(86, 99, 169);
  color: rgb(221, 219, 0);
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

input,
select,
button {
  background-color: rgb(86, 99, 169);
  border: 1.5px solid rgb(221, 219, 0);
  border-radius: 2px;
  padding: 0.25rem;
  color: rgb(221, 219, 0);
  margin-left: 0.5rem;
}

.input-div {
  margin-top: 1rem;
}

button {
  padding: 0.5rem 1.25rem;
  border: 3px solid whitesmoke;
  border-radius: 0.25rem;
  background-color: whitesmoke;
  font-size: 1rem;
  color: rgb(86, 99, 169);
  font-weight: bolder;
  z-index: 1000;
}

button:hover {
  cursor: pointer !important;
}

.header {
  background-color: whitesmoke;
  color: rgb(86, 99, 169);
  font-size: 3rem;
  padding-bottom: 0.5rem;
}

.input-fix {
  display: flex;
}

.phone-label {
  margin-right: 1rem;
}

.terms {
  width: 80%;
  display: block;
  margin: auto;
}

.terms-titles {
  color: whitesmoke;
}

.terms-bottom {
  position: fixed;
  width: 600px;
  bottom: 1rem;
  left: 50%;
  margin-left: -300px;
  box-shadow: 1px 35px 31px -19px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 1px 35px 31px -19px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 1px 35px 31px -19px rgba(0, 0, 0, 0.43);
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  display: block;
  margin: auto;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid rgb(221, 219, 0);
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.fade {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
